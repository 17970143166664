import { useState, forwardRef } from "react";
import {
  Eye as ShowPasswordIcon,
  EyeOffIcon as HidePasswordIcon,
} from "lucide-react";
import styles from "./password-field.module.css";

const INPUT_TYPE_PASSWORD = "password";
const INPUT_TYPE_TEXT = "text";

const PasswordField = forwardRef(({ disabled, value, onChange, id }, ref) => {
  const [type, setType] = useState(INPUT_TYPE_PASSWORD);
  const handleToggle = () => {
    if (type === "password") {
      setType(INPUT_TYPE_TEXT);
    } else {
      setType(INPUT_TYPE_PASSWORD);
    }
  };

  return (
    <div className={`${styles["password-field"]} field-container`}>
      <input
        className={styles["password"]}
        ref={ref}
        type={type}
        disabled={disabled}
        value={value}
        onChange={onChange}
        id={id}
      ></input>
      <span className={styles["password-eyeball"]} onClick={handleToggle}>
        {type === INPUT_TYPE_PASSWORD ? (
          <ShowPasswordIcon />
        ) : (
          <HidePasswordIcon />
        )}
      </span>
    </div>
  );
});

export { PasswordField };
