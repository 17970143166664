import { supabase } from "@/modules/supabase";

const getSQLAccountingControlACNumbers = async () => {
  const { data, error } = await supabase.from("lotus_control_ac").select("*");
  console.log("getSQLAccountingControlACNumbers api", data);
  console.log("getSQLAccountingControlACNumbers api error", error);
  return data;
};

const getSQLAccountingTerms = async () => {
  const { data, error } = await supabase.from("Terms").select();
  console.log("getSQLAccountingTerms api", data);
  console.log("getSQLAccountingTerms api error", error);
  return data;
};

const getSQLAccountingAgents = async () => {
  const { data, error } = await supabase.from("Agents").select();
  console.log("getSQLAccountingAgents api", data);
  console.log("getSQLAccountingAgents api error", error);
  return data;
};

const getSQLAccountingPriceTags = async () => {
  const { data, error } = await supabase.from("Price_Tags").select();
  console.log("getSQLAccountingPriceTags api", data);
  console.log("getSQLAccountingPriceTags api error", error);
  return data;
};

export {
  getSQLAccountingTerms,
  getSQLAccountingAgents,
  getSQLAccountingPriceTags,
  getSQLAccountingControlACNumbers,
};
