import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { MdInfoOutline as InfoIcon } from "react-icons/md";
import { toast } from "sonner";
import {
  Button,
  LoadingIndicator,
  HomeButton,
  PasswordField,
} from "@/modules/core/components/ui";
import { signInWithPassword } from "@/modules/core/api/auth";
import "../index.css";

const LoginPage = ({ loginCallback }) => {
  const emailInput = useRef(null);
  const passwordInput = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      emailInput.current.focus();
      return;
    }
    if (password === "") {
      passwordInput.current.focus();
      return;
    }
    const signIn = async ({ email, password }) => {
      setLoading(true);
      const { data, error } = await signInWithPassword(email, password);
      console.log(data);
      if (data?.session || data?.user) {
        await loginCallback({ byPassTimeout: true });
      }
      if (error) {
        if (error.status === 400) {
          toast.error(error.message);
        }
        console.log(error);
        if (password.length < 8) {
          setError("password-length");
        }
      }
      setLoading(false);
    };
    signIn({ email, password });
  };
  return (
    <div className="login-document-container">
      <div className="login-document">
        <HomeButton />
        <h1>Log in</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            className="field-container"
            ref={emailInput}
            type="email"
            disabled={isLoading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
          ></input>
          <label htmlFor="password">Password</label>
          <PasswordField
            ref={passwordInput}
            disabled={isLoading}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password"
          />
          {hasError === "password-length" && (
            <div className="tips-wrapper">
              <div className="tips">
                <InfoIcon />
                <p>Password must be at least 8 characters long</p>
              </div>
            </div>
          )}
          <div className="actions">
            <Button wide disabled={isLoading}>
              {isLoading ? (
                <LoadingIndicator offTimeout center type="auth" />
              ) : (
                "Continue"
              )}
            </Button>
            <div className="nav-container">
              <Link className="nav-btn secondary" to="/signup">
                Sign up
              </Link>
              <Link className="nav-btn tertiary" to="/forgot-password">
                Forgot password
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { LoginPage };
