import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { toast } from "sonner";
import { MdInfoOutline as InfoIcon } from "react-icons/md";
import {
  Button,
  LoadingIndicator,
  HomeButton,
  PasswordField,
} from "@/modules/core/components/ui";
import { getOneProfileByEmail } from "@/modules/core/api/users";
import { signUpWithEmailAndPassword } from "@/modules/core/api/auth";
import "../index.css";

const SignUpPage = () => {
  const emailInput = useRef(null);
  const passwordInput = useRef(null);
  const confirmPasswordInput = useRef(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [hasError, setError] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email === "") {
      emailInput.current.focus();
      return;
    }
    if (password === "") {
      passwordInput.current.focus();
      return;
    }
    if (confirmPassword === "") {
      confirmPasswordInput.current.focus();
      return;
    }
    if (password !== confirmPassword) {
      toast.warning("Passwords do not match");
      confirmPasswordInput.current.focus();
      return;
    }
    setLoading(true);
    const signUp = async ({ email, password }) => {
      // Check if account already exists
      const user = await getOneProfileByEmail(email);
      if (user) {
        toast.warning("Account already exists");
        setLoading(false);
        return;
      }
      // Sign up
      const { data, error } = await signUpWithEmailAndPassword(email, password);
      setLoading(false);
      if (data?.user || data?.session) {
        toast.success("A verification link has been sent to your email.");
      }
      if (error) {
        if (error.status === 429) {
          toast.error(error.message);
        } else {
          toast.error("Error signing up.");
          toast.error(error.message);
        }
        console.error(error);
        if (password.length < 8) {
          setError("password-length");
        }
      }
    };
    signUp({ email, password });
  };
  return (
    <div className="login-document-container">
      <div className="login-document">
        <HomeButton />
        <h1>Sign up Free</h1>
        <form onSubmit={handleSubmit}>
          <label htmlFor="email">Email</label>
          <input
            ref={emailInput}
            type="email"
            disabled={isLoading}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            id="email"
          ></input>
          <label htmlFor="password">Password</label>
          <PasswordField
            ref={passwordInput}
            disabled={isLoading}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password"
          />
          <label htmlFor="confirm-password">Confirm Password</label>
          <PasswordField
            ref={confirmPasswordInput}
            disabled={isLoading}
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            id="confirm-password"
          />
          {hasError === "password-length" && (
            <div className="tips-wrapper">
              <div className="tips">
                <InfoIcon />
                <p>Password must be at least 8 characters long</p>
              </div>
            </div>
          )}
          <div className="actions">
            <Button wide disabled={isLoading}>
              {isLoading ? (
                <LoadingIndicator offTimeout center type="auth" />
              ) : (
                "Sign up"
              )}
            </Button>
            <div className="nav-container">
              <Link className="nav-btn secondary" to="/login">
                Log in
              </Link>
              <Link className="nav-btn tertiary" to="/forgot-password">
                Forgot password
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export { SignUpPage };
