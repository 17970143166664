import { supabase } from "@/modules/supabase";

const getHORECAStaffFormApi = async (user_id) => {
  const { data, error } = await supabase
    .from("forms_horeca_staff_form")
    .select()
    .match({ user_id });
  console.log("get forms_horeca_staff_form api", data);
  console.log("get forms_horeca_staff_form api error", error);
  return data?.[0];
};

const insertHORECAStaffFormApi = async (keys) => {
  const { data, error } = await supabase
    .from("forms_horeca_staff_form")
    .upsert(keys)
    .select();
  console.log("insert forms_horeca_staff_form api", data);
  console.log("insert forms_horeca_staff_form api error", error);
  return data?.[0];
};

const getSQLAccountingMasterCustomerList = async () => {
  const { data, error } = await supabase.from("Customer_Master").select();
  console.log("getSQLAccountingMasterCustomerList api", data);
  console.log("getSQLAccountingMasterCustomerList api error", error);
  return data;
};

export {
  getHORECAStaffFormApi,
  insertHORECAStaffFormApi,
  getSQLAccountingMasterCustomerList,
};
